.container {
  background-color: rgba(237, 139, 0, 1);
  color: rgb(255, 255, 255);
  padding: 6px;
  padding-left: 18px;
  border-radius: 6px;
}

.container p {
  font-size: 1.15rem;
  font-weight: 600;
}
