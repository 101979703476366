.inactive {
  display: none;
  visibility: hidden;
}

.textbox {
  background-color: rgb(242, 242, 242);
  border-radius: 6px;
  padding: 12px 18px;
  margin: 0.3rem 0;
}

.formbox {
  width: 60%;
  align-self: center;
  margin: auto;
}

.btn__container {
  display: flex;
  justify-content: flex-end;
  margin-top: 0.67rem;
}

.input {
  display: block;
  font: inherit;
  border-radius: 4px;
  border: 1px solid rgb(204, 204, 204);
  padding: 0.25rem;
  width: 25%;
}

.questioninfo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.questioninfo h3 {
  margin-bottom: 0rem;
}

.tooltip {
  margin: 0;
  padding: 0;
  border: 0;
}

.tooltip p {
  margin: 0;
}

.icon {
  vertical-align: middle;
  color: rgb(110, 110, 110);
}

.main__input__container {
  width: fit-content;
  margin: 0.6rem auto 0 0;
}

.parttime__container,
.button__container {
  width: 100%;
  margin: 0.2rem auto;
}

.parttime__container,
.button__container {
  display: flex;
  flex-direction: row;
  align-self: center;
}

.parttime__container {
  justify-content: flex-start;
}

.button__container {
  margin-left: 1.7rem;
  margin-top: 0.75rem;
  margin-bottom: 0rem;
}

.warnings p {
  color: red;
  margin: 0.25rem auto 0.25rem 1.7rem !important;
}

.parttime__datepicker,
.parttime__proportion {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* justify-content: space-between; */
}

.parttime__datepicker input,
.parttime__proportion input {
  display: block;
  font: inherit;
  border-radius: 4px;
  border: 1px solid rgb(204, 204, 204);
  padding: 0.25rem;
  width: 8rem;
  margin-left: 0.75rem;
}

.label {
  margin-left: 0.9rem;
  margin-top: 0.2rem;
  margin-bottom: 0.1rem;
  width: fit-content;
}

.parttime__text h3,
.parttime__text p {
  margin-bottom: 0.5rem;
}

.parttime__text p:last-child {
  margin-bottom: 0;
}

.parttime__index {
  align-self: end;
  padding-bottom: 0.3rem;
}

.button {
  font: inherit;
  cursor: pointer;
  background-color: rgba(149, 39, 44, 0.95);
  color: rgb(255, 255, 255);
  border: 2.5px solid rgba(149, 39, 44, 0.95);
  border-radius: 4px;
  font-size: 1.2rem;
  padding: 0.2rem 0.75rem;
}

.button:not(:nth-of-type(1)) {
  margin-left: 0.5rem;
}

.button:hover {
  color: rgb(255, 255, 255);
  border: 2.5px solid rgba(149, 39, 44, 0.85);
  background-color: rgba(149, 39, 44, 0.85);
}

p.err_message {
  color: red;
  text-align: center;
  animation: appear 0.15s linear;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  opacity: 1;
}

@keyframes appear {
  0% {opacity: 0}
  99% {opacity: 0}
  100% {opacity: 1; margin-bottom: 20px; margin-top: 20px;}
}