.section {
  display: flex;
  flex-direction: row;
}

.section div:nth-of-type(1) {
  margin-right: 2rem;
  padding-right: 2rem;
  border-right: 1px solid rgb(0, 0, 0);
}

.container {
  display: flex;
  flex-direction: column;
  background-color: rgb(242, 242, 242);
  border-radius: 6px;
  padding: 0.75rem 1.25rem;
  flex: 6;
  margin: 1rem 0;
}

.container h3 {
  margin: 0.25rem 0 0.25rem;
}

.container h3:nth-of-type(2) {
  margin-top: 1.25rem;
}

.container h2 {
  margin: 0;
}

.container p {
  margin: 0;
}
