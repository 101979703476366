.modal {
    border: 1px solid black;
    border-radius: 6px;
    background-color: hsl(0, 0%, 100%);
    padding: 1rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    width: 40%;
    position: fixed;
    left:30%;
    top: 6.5rem;
    align-items: center;
    transition: transform 0.1s linear;
    animation-name: grow;
    animation-duration: 0.1s;
    animation-timing-function: ease-in-out;
  }
  
  @keyframes grow {
    0%  {transform: scale(0.1) translateX(-260%)}
    100%  {transform: scale(1) translateX(0)}
  }
  .modal.modal_shifted {
    transform: translateX(-270%);
    width: 10%;
    padding: 5px;
    margin-left:0;
    margin-right:0;
    margin-top:0;
    position: fixed;
    animation: none;
    flex-direction: column;

  }

  .video {
    height: 80%;
    width: 100%;
    border: 2px solid black;
    border-radius: 3px;
  }
  
  .survey_btn {
    font: inherit;
    padding: 0.5rem 1.5rem;
    cursor: pointer;
    border-radius: 4px;
    background-color: var(--main-color);
    color: hsl(0, 0%, 100%);
    border: 1px solid var(--main-color);
  }
  
  .survey_btn:hover {
    background-color: var(--border-color);
    border-color: var(--border-color);
  }
  
  .close_btn {
    background: none !important;
    border: none;
    padding: 0 !important;
    color: var(--main-color);
    text-decoration: underline;
    cursor: pointer;
    margin-top: 0.5rem;
  }