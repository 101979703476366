.background {
  position: fixed;
  z-index: 5;
  background-color: hsla(0, 0%, 0%, 0.75);
  width: 800vw;
  height: 1000vh;
  top: 0;
  left: 0;
}

.modal {
  box-shadow: 0 1px 4px hsla(0, 0%, 0%, 0.2);
  border-radius: 6px;
  background-color: hsl(0, 0%, 100%);
  padding: 1.2rem;
  text-align: center;
  width: 80%;
  position: fixed;
  z-index: 10;
  top: 5%;
  left: 10%;
}

.modal p {
  text-align: justify;
}

.btn_main {
  font: inherit;
  padding: 0.5rem 1.5rem;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  background-color: rgb(149, 39, 44);
  color: hsl(0, 0%, 100%);
  border: 1px solid rgb(149, 39, 44);
  margin-top: 20px;
}

.btn_main:hover {
  background-color: rgba(149, 39, 44, 0.85);
  border-color: rgba(149, 39, 44, 0.85);
}
