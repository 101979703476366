.mainContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1.1rem;
}

.linkContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
