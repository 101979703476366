.pagebutton {
  font: inherit;
  cursor: pointer;
  background-color: rgb(149, 39, 44);
  color: rgb(255, 255, 255);
  padding: 0.5rem 1.5rem;
  border: 1px solid rgb(149, 39, 44);
  border-radius: 4px;
  font-weight: bold;
  font-size: 1.25rem;
}

.pagebutton:hover,
.pagebutton:active {
  background-color: rgba(149, 39, 44, 0.85);
  border-color: rgba(149, 39, 44, 0.85);
}
