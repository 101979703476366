.modal {
  position: fixed;
  top: 40%;
  right: 1.5rem;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.4);
  border-radius: 6px;
  background-color: rgb(255, 255, 255);
  padding: 1rem;
  text-align: center;
  z-index: 4;
  display: flex;
  flex-direction: column;
  width: 16rem;
}

.survey_btn {
  font: inherit;
  padding: 0.5rem 1.5rem;
  cursor: pointer;
  border-radius: 4px;
  background-color: rgb(149, 39, 44);
  color: hsl(0, 0%, 100%);
  border: 1px solid rgb(149, 39, 44);
}

.survey_btn:hover {
  background-color: rgba(149, 39, 44, 0.85);
  border-color: rgba(149, 39, 44, 0.85);
}

.close_btn {
  background: none !important;
  border: none;
  padding: 0 !important;
  color: rgb(149, 39, 44);
  text-decoration: underline;
  cursor: pointer;
  margin-top: 0.5rem;
}
