.switch_container {
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  
  .switch_container :first-child {
    margin-right: 0.5rem;
  }
  
  .chart_container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 1rem;
  }
  
  .chart {
    width: 50%;
    height: 26rem;
  }
  
  .joint_container {
    margin-top: 1rem;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: center;
  }
  
  .joint_container :first-child {
    margin-right: 0.5rem;
  }
  
  .chart2 {
    width: 80%;
    height: 26rem;
    margin-left: 10%;
  }
  
  .switch_container2 {
    display: flex;
    justify-content: center;
    flex-direction: row
  }