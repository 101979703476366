.pageTabs {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-self: flex-end;
}

@media (max-width: 1880px) {
  .pageTabs {
    align-self: center;
  }
}

.pageTab {
  text-decoration: none;
  color: #fff;
  padding: 0rem 1rem;
  margin: 0;
}

.pageTab:nth-last-child(1) {
  padding-right: 0;
}

.pageTab:hover,
.pageTab_active {
  text-decoration: underline;
  text-decoration-thickness: 2px;
}

.pageTab_inactive {
  text-decoration: none;
  color: rgba(240, 238, 238, 0.8);
}

.pageTab_inactive:hover {
  text-decoration: none;
  cursor: not-allowed;
}
